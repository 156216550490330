/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500&display=swap');
*/

.active-trader-container {
    background: hsl(220, 0%, 15%);
    color: hsl(0, 0%, 82%);
    font-size: 12px;
    font-weight: 300;
    -webkit-font-smoothing: subpixel-antialiased;
}

.active-trader-container .mono {
    font-size: 11px;
}

.active-trader-container .panel {
    padding: 8px;
}
