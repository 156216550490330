:root {
    --c1: hsl(220, 0%, 18%);
    --c2: hsl(220, 0%, 18%);
    --w: 2px;
 }

body.reflex-col-resize {
  cursor: col-resize; }

body.reflex-row-resize {
  cursor: row-resize; }

.reflex-container {
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%; }

.reflex-container.horizontal {
  flex-direction: column;
  min-height: 1px; }

.reflex-container.vertical {
  flex-direction: row;
  min-width: 1px; }

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%; }

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; }

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%; }

.reflex-container > .reflex-splitter {
  background-color: var(--c1);
  z-index: 100; }

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: var(--c2);
  transition: all 0.2s ease; }

.horizontal > .reflex-splitter {
  border-bottom: 1px solid var(--c2);
  border-top: 1px solid var(--c2);
  cursor: row-resize;
  width: 100%;
  height: var(--w); }

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; }

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 1px solid var(--c1);
  border-top: 1px solid var(--c1); }

.reflex-container.vertical > .reflex-splitter {
  border-right: 1px solid var(--c2);
  border-left: 1px solid var(--c2);
  cursor: col-resize;
  height: 100%;
  width: var(--w); }

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; }

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 1px solid var(--c1);
  border-left: 1px solid var(--c1); }

.reflex-container > .reflex-splitter.reflex-thin {
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 100; }

.reflex-container > .reflex-splitter.reflex-thin.active
.reflex-container > .reflex-splitter.reflex-thin:hover {
  transition: all 0.2s ease;
  opacity: 0.5; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  border-top: 8px solid rgba(255, 255, 255, 0);
  height: 17px !important;
  cursor: row-resize;
  margin: -8px 0;
  width: 100%; }

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 8px solid var(--c2);
  border-top: 8px solid var(--c2); }

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 8px solid rgba(255, 255, 255, 0);
  border-left: 8px solid rgba(255, 255, 255, 0);
  width: 17px !important;
  cursor: col-resize;
  margin: 0 -8px;
  height: 100%; }

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 8px solid var(--c2);
  border-left: 8px solid var(--c2); }

