.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Polaris-Spinner, .centered-spinner {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.Polaris-Spinner > svg {
  animation-duration: 1.0s !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Polaris-Navigation__PrimaryNavigation {
  height: 100%;
}

.Polaris-FooterHelp {
    position: absolute;
    bottom: 0;
}

.Polaris-FooterHelp p {
    text-align: center;
    font-size: 10px;
    opacity: 0.6;
}

.Polaris-Navigation__ListItem > button {
  border-top: 0px !important;
}

.Polaris-Frame .Polaris-Navigation {
  background: hsl(220, 5%, 95%);
  border-right: 1px solid hsl(220, 3%, 90%);
}

.Polaris-Navigation__Section > .Polaris-Navigation__SectionHeading {
  padding-left: 26px;
}

.Polaris-Navigation__ListItem > .Polaris-Navigation__Item > .Polaris-Navigation__Text {
  line-height: 18px !important;
  padding-left: 6px;
}

.Polaris-Navigation__Section > .Polaris-Navigation__SectionHeading * {
  font-weight: 700;
  opacity: 0.75;
}

.btn:hover {
  background: hsl(0, 0%, 92%);
  cursor: pointer;
}

.chart-btn {
  background: hsl(0, 0%, 92%);
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  opacity: 0.8;
  border: 1px solid hsl(0, 0%, 75%);
  background: hsl(0, 0%, 100%);
  font-size: 12.5px;
  margin-left: 18px;
  position: relative;
  bottom: 4px;
}

.chart-btn:hover {
  background: hsl(0, 0%, 94%);
}


.subtle-btn {
  opacity: 0.15;
  padding: 4px 4px;
  cursor: pointer;
  z-index: 99;
}

.subtle-btn:hover {
  opacity: 0.6;
}

select[multiple] {
  width: 100%;
  outline: none;
  height: 14rem;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
}

select[multiple] option {
  font-size: 14px; 
  padding: 8px 12px;
}

button {
  border-radius: 3px;
  border-width: 1px;
  border-color: hsl(0, 0%, 75%);
  margin: 2px 6px 2px 2px;
  padding: 5px 9px;
}

button.action.minor {
  background: none;
  border: none;
  text-decoration: none;
  color: hsl(220, 10%, 50%);
}

button.action.minor:hover {
  color: hsl(220, 50%, 50%);
}

button.action.minor:hover {
  cursor: pointer;
}

span.fakeActionButton {
  padding: 8px 14px;
  border: 1px solid hsl(0, 0%, 75%);
  border-radius: 5px;
  background: hsl(0, 0%, 100%);
  font-size: 14px;
  font-weight: 500;
}

span.fakeActionButton:hover {
  background: hsl(0, 0%, 96%);
  cursor: pointer;
}
