
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mono {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-weight: 300;
  font-size: 12px;
  white-space: pre-wrap;
  line-height: 1.5;
}

.heavy-gray {
  cursor: default;
  font-weight: 800;
  opacity: 0.6;
}

.bold {
  font-weight: 700;
}

table.tabledump, .tabledump tr, .tabledump td, .tabledump th {
  border: 1px solid hsl(0, 0%, 70%);
  border-collapse: collapse;
  padding: 2px 6px;
}

.tabledump th {
  background-color: hsl(0, 0%, 94%);
}

.Polaris-Navigation {
  background: hsl(212, 2%, 91%) !important;
  box-shadow: 0px 0px 18px hsla(0, 0%, 100%, 0.2) !important;
  max-width: 200px !important;
  min-width: 200px !important;
}

.Polaris-Frame__Main {
  padding-left: 200px !important;
}

.Polaris-Page {
  padding: 4px 24px !important;
}

.Polaris-Frame__Content {
  background: hsl(220, 6%, 97%);
}

.Polaris-Card {
  margin: 24px 0px;
  border-radius: 4px !important;
  border: 1px solid hsl(220, 0%, 100%);
  box-shadow: 0px 2px 8px hsla(0, 0%, 0%, 0.12) !important;
}

.Polaris-Page-Header {
  padding-bottom: 0px !important;
}

.Polaris-Card h1 {
  line-height: 150%;
  font-size: 22px;
  font-weight: 600;
}

.Polaris-Card h2 {
  line-height: 150%;
  font-size: 18px;
  font-weight: 600;
}

.Polaris-Card h3 {
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
}

.p1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.p2 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.p05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.opa0 { opacity: 0.0; }
.opa1 { opacity: 0.1; }
.opa2 { opacity: 0.2; }
.opa3 { opacity: 0.3; }
.opa4 { opacity: 0.4; }
.opa5 { opacity: 0.5; }
.opa6 { opacity: 0.6; }
.opa7 { opacity: 0.7; }
.opa8 { opacity: 0.8; }
.opa9 { opacity: 0.9; }

.hoverable:hover {
    opacity: 1.0;
}

.prose > * {
  margin-bottom: 1em;
}

.wtt-chart-label .Polaris-Choice__Label * {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px !important;
  opacity: 0.8;
}

.highcharts-credits {
  opacity: 0;
}

.cm-editor {
  border-radius: 4px !important;
  border: 1px solid hsla(0, 0%, 80%) !important;
}


.cm-editor.cm-focused {
  outline: solid;
  outline: none;
  outline-color: hsla(220, 80%, 60%);
  border: 1px solid hsla(220, 80%, 60%) !important;
}

.cm-editor .cm-line {
  padding: 3px;
  padding-left: 12px;
}

/* Needed to make the icon-link columns a bit more compact */
.ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 10px !important;
  padding-right: 6px !important;
}