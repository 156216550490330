.ag-theme-alpine-dark {
    --ag-font-size: 10px;
    --ag-font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;

    --ag-foreground-color: hsl(220, 0%, 82%);
    --ag-background-color: hsl(220, 0%, 9%);
    --ag-odd-row-background-color: hsl(220, 0%, 11%) ;
    --ag-header-foreground-color: hsl(0, 0%, 72%);
    --ag-header-background-color: hsl(220, 0%, 16%);
    --ag-header-column-resize-handle-color: hsl(220, 0%, 26%);

    --ag-grid-size: 2px;
    --ag-row-height: 20px;
    --ag-border-color: hsl(0, 0%, 14%);
    --ag-row-border-color: none;

    font-weight: 300;
}

.active-trader-container .ag-row-focus {
    background: hsl(212, 10%, 28%);
}

