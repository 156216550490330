
.ag-theme-alpine {
  --ag-grid-size: 5px;
  --ag-header-height: 32px;
  --ag-cell-horizontal-padding: 12px;
  --ag-foreground-color: hsl(0, 0%, 25%);
}

.ag-theme-alpine {
    --ag-header-background-color: hsl(0, 0%, 93%);
}

.ag-cell-focus,.ag-cell-no-focus{
    border-color: transparent !important;
}
.no-border.ag-cell:focus{
    border-color: transparent !important;
    outline: none;
}