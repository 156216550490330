.clickable:hover {
    cursor: pointer;
}

.alert {
    cursor: default;
    padding: 6px 1px;
    position: relative;
}

.alert .buttons {
    position: absolute;
    right: 0;
}

.button {
    font-size: 16px;
    opacity: 0;
    padding: 6px;
}

.alert:hover .button {
    opacity: 0.5;
}

.alert:hover .button:hover {
    cursor: pointer;
    opacity: 1.0;
}


.contextJson {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 500;
    font-size: 12px;
    white-space: pre-wrap;
    line-height: 1.5;
    border: 1px solid hsl(0, 0%, 85%);
    background: hsl(210, 2%, 94%);
    border-radius: 4px;
    padding: 14px;
    margin-top: 1em;
    margin-bottom: 1em;
}

