.progressVisualFull {
    display: flex;
    /* change the value if you want different thickness */
    height: 5px;
    margin: 20px 0;
  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 1.0s;
    transition-timing-function: cubic-bezier(.3, 1, .3, 1);
  }
  
  .progressLabel {
    /* Nothing here, move along */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  