/** @format */

#AppFrameMain
	> div
	> div
	> div:nth-child(2)
	> div:nth-child(3)
	> div
	> div
	> div.Polaris-DataTable
	> div
	> table
	> tbody
	> tr:nth-child(1)
	> th {
	width: 20px;
}

#AppFrameMain
	> div
	> div
	> div:nth-child(2)
	> div:nth-child(3)
	> div
	> div
	> div.Polaris-DataTable
	> div
	> table
	> tbody
	> tr
	> th {
	width: 20px;
}
