* {
    outline: none;
}

.busy {
    opacity: 0.2 !important;
    transition: opacity 3.0s;
    transition-delay: 1.0s;
}

.flexcontainer {
    display: flex;

}

.flexchild {
    outline: none;
    flex: 1 1 0px;
    margin-right: 28px;
    overflow-y: auto;
}

.venue {
    background-color: hsl(0, 0%, 99%);
    border: 1px solid hsl(0, 0%, 95%);
    border-radius: 5px;
    margin: 1em 1em;
    padding: 2em 2em;
    user-select: none;
}

.venue .name {
    font-weight: 700;
}

.venue.vi {
    background-color: hsl(270, 50%, 93%);
}

.venue.tm {
    background-color: hsl(225, 50%, 93%);
}

.venue:hover {
    cursor: pointer;
}

.venue.active {
    border-color: hsl(0, 0%, 20%);
    box-shadow: 0px 2px 4px hsl(0, 0%, 70%);
    border-left-color: hsl(120, 40%, 50%);
    border-left-width: 7px;
}

.shortcuts {
    margin: 0.5rem 1rem;
}

.venuegroup {
    border: 2px solid hsl(220, 0%, 50%);
    border-radius: 7px;
    padding: 0px;
    margin: 2em 1em;
}

.venuegroup.active {
    background: hsl(0, 0%, 93%);
    box-shadow: 0px 2px 4px hsl(0, 0%, 70%);
    border-left-color: hsl(0, 90%, 50%);
    border-left-width: 7px;
}

.venuegroup .venue {
    margin: 1em 1em;
    padding: 0em 1em;
    background: none;
    border: none;
}

button {
    margin: 2px 4px 2px 2px;
    padding: 6px 8px;
}

.prose h1 {
    margin-top: 2em;
    font-size: 15px;
    font-weight: 500;
}

.prose p {
    margin-top: 1em;
}

h2, .timestamp {
    font-size: 15px;
    font-weight: 600;
    margin-left: 2px;
    line-height: 150%;
}

.eventGroup {
    user-select: none;
    border: 3px solid hsla(120, 0%, 90%, 0.01);
    border-radius: 5px;
    margin-right: 24px;
    padding: 12px 18px;
}

.event {
    border: 1px solid hsl(0, 0%, 50%);
    background: hsla(0, 0%, 100%, 1.0);
    user-select: none;
    border-radius: 3px;
    padding: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.event:hover {
    cursor: auto !important;
}


.unprocessed .easyMatch {
    background: hsl(120, 0%, 100%);
    border-color: hsl(110, 50%, 50%);
    margin-top: 18px;
    margin-bottom: 18px;
}

.unprocessed .easyMatch .event {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.easyMatch:hover {
    cursor: pointer;
}

.processed .easyMatch, .processed .noCrossSource, .processed .eventGroup {
    border: 2px solid hsl(120, 0%, 85%);
    opacity: 1;
    background: hsla(120, 0%, 100%, 0.99);
    margin-top: 16px;
    margin-bottom: 16px;
}

.processed .event, .easyMatch .event {
    padding: 2px;
    border-color: white;
    margin-top: 2px;
    margin-bottom: 2px;
}

.event:hover {
    cursor: pointer;
}

.unprocessed .event.active {
    background: hsl(110, 50%, 90%);
    border-color: hsl(120, 99%, 30%);
}

.unprocessed .noCrossSource .event, .unprocessed .noCrossSource .timestamp {
    opacity: 0.5;
}

.unprocessed .noCrossSource .event.active {
    opacity: 1.0;
}

.unprocessed .noCrossSource {
    margin-top: -10px;
    margin-bottom: -10px;
}

.tmSource {
    font-weight: 800;
    color: hsl(210, 88%, 50%);
}

.viSource {
    font-weight: 800;
    color: hsl(285, 88%, 50%);
}

.toggleOn {
    background: hsl(120, 35%, 88%);
}

.toggleOff {
    border-color: transparent;
    background-color: transparent;
}

.toggleOff:hover {
    background: hsl(0, 0%, 92%);
}

.modeToggleActive {
    background: hsl(215, 50%, 45%);
    border: transparent;
    color: white;
    font-weight: 600;
}

button.big {
    font-size: 13px;
    margin: 0;
    margin-right: 8px;
    padding: 10px 12px;
    border: 1px solid hsl(0, 0%, 70%);
    font-weight: 600;
}

button.big:hover {
    cursor: pointer;
}

button.big.green {
    background: hsl(165, 99%, 25%);
    border-color: transparent;
    color: white;
}

.rectangled {
    border: 1px solid hsl(0, 0%, 60%); 
    border-radius: 5px;
    margin: 12px 0px; 
    padding: 12px 18px;
}