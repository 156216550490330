
.layoutButton {
    padding: 6px 6px;
    margin-right: 1px;
    font-size: 14px;
    line-height: 1;
    align-items: center;
    justify-content: center;
    display: inline-block;
    border-radius: 4px;
}

.layoutButton:hover {
    background: hsl(0, 0%, 89%);
    cursor: pointer;
}

.layoutButton.active {
    background: hsl(220, 50%, 55%);
    color: hsl(224, 0%, 100%);
}